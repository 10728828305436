<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Device UUID</label>
                    <input class="form-control" type="text" v-model="inputFilter.device_uuid" @keyup.enter="fetchLead">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="email" v-model="inputFilter.email" @keyup.enter="fetchLead">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchLead" :is-loading="isFetching"></button-filter>
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Device UUID</th>
                            <th>Customers</th>
                            <th>Created At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="leads.length > 0">
                        <tr v-for="lead in leads" :key="lead.id">
                            <td>{{ lead.deviceUuid }}</td>
                            <td>
                                <div v-for="(user,index) in lead.users">
                                    <div v-if="index >= 1">
                                    <router-link :to="{ name: 'customer-show', params: { userId: user.id } }">
                                        {{ user.email }}
                                    </router-link>
                                </div>
                                </div>
                            </td>
                            <td>{{ lead.createdAt ? formatDate(lead.createdAt, 'DD-MMM-YYYY hh:mm:ss A') : '' }}</td>
                            <td class="text-end">
                                <div class="btn-group align-text-top">
                                    <router-link class="btn btn-icon btn-primary"
                                        :to="{ name: 'lead-show', params: { userId: lead.users[0].id } }">
                                        <icon name="eye" />
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetching" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import { ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import LeadService from '@/services/users/LeadService'
import Lead from '@/types/Lead'

const leads = ref<Array<Lead>>([])
const inputFilter = ref<UserFilter>({
    device_uuid: '',
    email: '',
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const isFetching = ref<boolean>(false)

fetchLead()


watch(
    () => metaPageTriggered.value,
    () => {
        fetchLead()
    }
)

function resetFilter() {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchLead()
}

function fetchLead() {
    isFetching.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    LeadService.index(query)
        .then(({ data: { data, meta } }) => {
            leads.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetching.value = false
        })
}

watch(
    () => metaPageTriggered.value,
    () => {
        fetchLead()
    }
)
</script>
