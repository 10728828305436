<template>
    <div class="input-group">
        <input 
            type="text" 
            :id="inputId" 
            class="form-control bg-white" 
            :placeholder="placeholder" 
            readonly="true" 
        />
        <span class="input-group-text">
            <icon name="calendar" />
        </span>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { easepick } from '@easepick/core'
import useDateRangePicker from '@/composable/useDateRangePicker'

const DEFAULT_DATE_FORMAT = 'DD-MMM-YYYY'
const { easePickDefaultOptions } = useDateRangePicker()

const props = defineProps({
    inputId: {
        type: String,
        default: 'dateRangePicker',
    },
    placeholder: {
        type: String,
        default: 'Select date range',
    },
    initialFromDate: {
        type: String,
        default: '',
    },
    initialToDate: {
        type: String,
        default: '',
    },
})

const emit = defineEmits(['update:dateRange'])
const statusStatFilter = ref({
    from_date: '',
    to_date: '',
})

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById(props.inputId),
        setup: (picker) => {
            picker.on('select', (e) => {
                statusStatFilter.value = {
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT),
                }
                emit('update:dateRange', statusStatFilter.value)
                picker.hide()
            })
        },
    })

    // Only set date range if both initial dates are provided
    if (props.initialFromDate && props.initialToDate) {
        picker.setDateRange(props.initialFromDate, props.initialToDate)
        statusStatFilter.value = {
            from_date: props.initialFromDate,
            to_date: props.initialToDate,
        }
    }
})

watch(
    () => [props.initialFromDate, props.initialToDate],
    ([newFromDate, newToDate]) => {
        if (newFromDate && newToDate) {
            statusStatFilter.value = {
                from_date: newFromDate,
                to_date: newToDate,
            }
        } else {
            statusStatFilter.value = {
                from_date: '',
                to_date: '',
            }
        }
    }
)
</script>