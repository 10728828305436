import User from '@/types/User'
import { defineStore } from 'pinia'
import { version } from '../../package.json'

export const useGeneralStore = defineStore('general', {
    state: () => {
        return {
            user: {} as User,
            sendbird: { nickname: '', token: '' },
            telescopeUrl: '',
            horizonUrl: '',
            resourceHash: localStorage.getItem('resourceHash'),
            backofficeVersion: version,
            isInitCompleted: false,
            isGoogleScriptLoaded: false,
        }
    },
})
