<template>
    <div class="card">
        <form @submit.prevent="saveAnnouncement">
            <div class="card-body">
                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">Recipient <span class="text-red">*</span></label>
                    <div class="col">
                        <label class="form-check form-check-inline me-5" v-for="recipient in announcementRecipients" :key="recipient.key">
                            <input class="form-check-input" type="radio" v-model="inputUser.recipient" name="inputUser.recipient" :value="recipient.key" :id="`recipient-${recipient.key}`" :disabled="viewOnly">
                            <span class="form-check-label">{{ recipient.label }}</span>
                        </label>
                    </div>
                    <small class="text-red" v-if="hasError('recipient')">{{ getError('recipient') }}</small>
                </div>

                <div class="form-group mb-4 row" v-if="inputUser.recipient === 4 && (announcement === undefined || announcement.status === AnnouncementStatusEnum.STATUS_DRAFT)">
                    <label class="form-label col-3 col-form-label">CSV file</label>
                    <div class="col">
                        <input type="file" accept=".csv" ref="fileCsv" @change="handleCsvFile">
                        <small class="invalid-feedback" v-if="hasError('csv_file')">{{ getError('csv_file') }}</small>
                    </div>
                </div>

                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">Title<span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('title') }" type="text" v-model="inputUser.title" @focus="clearError('title')" :disabled="viewOnly">
                        <small class="invalid-feedback" v-if="hasError('title')">{{ getError('title') }}</small>
                    </div>
                </div>

                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">Preview <span class="text-red">*</span></label>
                    <div class="col">
                        <textarea style="resize: none" class="form-control" :class="{ 'is-invalid': hasError('preview') }" type="text" v-model="inputUser.preview" @focus="clearError('preview')" :disabled="viewOnly" maxlength="255"></textarea>
                        <small class="invalid-feedback" v-if="hasError('preview')">{{ getError('preview') }}</small>
                    </div>
                </div>

                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">Message<span class="text-red">*</span></label>
                    <div class="col">
                        <div v-if="viewOnly">
                            <MdPreview editorId="editBody" :modelValue="inputUser.body ?? ''" class="border" />
                        </div>
                        <div v-else>
                            <MdEditor editorId="createBody" language="en-US" :toolbarsExclude="excludedToolbars" @onFocus="clearError('body')" v-model="inputUser.body" />
                        </div>
                        <small class="text-red" v-if="hasError('body')">{{ getError('body') }}</small>
                    </div>
                </div>

                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">Send this announcement at<span class="text-red">*</span></label>
                    <div class="col">
                        <div class="mb-4">
                            <div class="input-group">
                                <input type="text" id="datetimepicker" class="form-control" placeholder="Select date" :value="sendAtDisplay" required :disabled="viewOnly">
                                <span class="input-group-text">
                                    <icon name="calendar"/>
                                </span>
                            </div>
                            <small class="text-red" v-if="hasError('send_at')">{{ getError('send_at') }}</small>
                            <small class="fst-italic" v-if="!viewOnly">Please scheduled at least 5 minutes from current time, and 5 minutes gap between other announcement</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" v-if="!viewOnly">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit">Save Announcement</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { toLocaleDate } from '@/composable/useDate'
import useFormError from '@/composable/useFormError'
import { AnnouncementStatusEnum } from '@/enums/AnnouncementStatusEnum'
import { $externalResults, rules as AnnouncementRule } from '@/rules/AnnouncementRule'
import AnnouncementService from '@/services/AnnouncementService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import Announcement from '@/types/Announcement'
import UserInput from '@/types/Input'
import { Core, easepick } from '@easepick/core'
import { LockPlugin } from '@easepick/lock-plugin'
import { TimePlugin } from '@easepick/time-plugin'
import { useVuelidate } from '@vuelidate/core'
import { MdEditor, MdPreview } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const { addToastNotification } = useNotificationsStore()
const { announcementRecipients } = storeToRefs(useResourcesStore())
const router = useRouter()

const props = defineProps({
    announcement: { type: Object as () => Announcement, required: false },
})

const mode = computed(() => {
    if (props.announcement !== undefined) {
        return 'update'
    }

    return 'create'
})

const inputUser = ref<UserInput>({
    recipient: 1,
    title: '',
    preview: '',
    body: '',
    send_at: null,
    csv_file: null,
})

const excludedToolbars: Array<string> = [
    'task',
    'image',
    'table',
    'mermaid',
    'katex',
    'revoke',
    'next',
    'save',
    'pageFullscreen',
    'fullscreen',
    'htmlPreview',
    'catalog',
    'github',
]

const v$ = useVuelidate(AnnouncementRule(inputUser.value), { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

const DEFAULT_ANNOUNCEMENT_DATE_FORMAT = 'DD-MMM-YYYY hh:mm A'
const sendAtDisplay = computed(() => inputUser.value.send_at ? toLocaleDate(inputUser.value.send_at * 1000, DEFAULT_ANNOUNCEMENT_DATE_FORMAT) : '')

let sendAtDatePicker: Core | null = null
const easePickDefaultOptions = {
    format: DEFAULT_ANNOUNCEMENT_DATE_FORMAT,
    css: [
        'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.0/dist/index.css',
    ],
    autoApply: false,
    zIndex: 10,
    plugins: [TimePlugin, LockPlugin],
    TimePlugin: {
        format12: true,
    },
    LockPlugin: {
        minDate: new Date(),
    },
}

const viewOnly = computed((): boolean => {
    if ((mode.value === 'update') && (props.announcement !== undefined)) {
        return props.announcement.status !== AnnouncementStatusEnum.STATUS_DRAFT
    }

    return false
})

const handleCsvFile = (event: Event) => {
    if (event.target instanceof HTMLInputElement) {
        const selectedFile = event.target.files ? event.target.files[0] : null

        if (selectedFile) {
            inputUser.value.csv_file = selectedFile
        }
    }
}

const saveAnnouncement = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (!validated) {
        return
    }

    const announcementService = props.announcement === undefined
        ? AnnouncementService.store(inputUser.value)
        : AnnouncementService.update(props.announcement.id, inputUser.value)

    announcementService
        .then(() => {
            addToastNotification({
                message: `Announcement ${mode.value}d`,
                type: 'success',
            })
            router.push({ name: 'announcement-index' })
        })
        .catch(({ response: { data, status } }) => {
            if (status === 422) {
                Object.assign($externalResults.value.inputUser, data.errors)
            } else {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            }
        })
}

onMounted(() => {
    if (!viewOnly.value) {
        sendAtDatePicker = new easepick.create({
            ...easePickDefaultOptions,
            ...{
                element: '#datetimepicker',
                setup: (picker: Core) => {
                    picker.on('select', (e: any) => {
                        inputUser.value.send_at = e.detail.date.getTime() / 1000
                    })
                },
            },
        })
    }

    if (props.announcement !== undefined) {
        inputUser.value.recipient = props.announcement.recipient
        inputUser.value.title = props.announcement.title
        inputUser.value.preview = props.announcement.preview
        inputUser.value.body = props.announcement.body
        inputUser.value.send_at = new Date(props.announcement.sendAt).getTime() / 1000

        const sendTimestamp = inputUser.value.send_at * 1000

        sendAtDatePicker?.setDate(sendTimestamp)
        sendAtDatePicker?.setTime(toLocaleDate(sendTimestamp, 'HH:mm'))
        sendAtDatePicker?.gotoDate(sendTimestamp)
    }
})
</script>
