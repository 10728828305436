<template>
    <div>
        <router-link :to="{ 'name': 'lead-index' }" class="d-inline-flex align-items-center" role="button">
            <icon name="chevron-left" />
            <span>Back to Lead</span>
        </router-link>
        <overlay-spinner :is-showing="isFetchingLead" v-if="!user" />
        <template v-else>
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="d-flex">
                            <h2 class="page-title">{{ user.leads[0].deviceUuid }}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-cards mt-2 mb-3">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h4>Total Lead Customers</h4>
                                    <h1 class="fw-normal mb-0">{{ stats.totalCustomers }}</h1>
                                </div>
                                <span class="bg-blue-lt text-center rounded-circle pt-2"
                                    style="height:48px; width: 48px">
                                    <icon name="users" :size="32" :stroke-width="1.2" class="text-blue" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <sub-menu :items="subMenus" :active="activeComponent"/>
            <div class="py-4">
                <KeepAlive>
                    <component :is="activeComponent.component" v-bind="activeComponent.props"
                        :key="`${activeComponent.slug}-${Date.now()}`">
                    </component>
                </KeepAlive>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import LeadService from '@/services/users/LeadService'
import { useNotificationsStore } from '@/stores/notifications'
import User from '@/types/User'
import { stat } from 'fs'
import { computed, defineAsyncComponent, markRaw, onMounted, provide, ref } from 'vue'
import { useRoute } from 'vue-router'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const userId = Number(route.params.userId);
const user = ref<User>()
const stats = ref({
    totalCustomers: 0
});
const isFetchingLead = ref<boolean>()
const activeComponent = ref<any>({})
const subMenus = ref<Array<any>>([])

provide('setActiveComponent', setActiveComponent)

fetchLead()

const isUserDeleted = computed((): boolean => user.value?.isDeleted ?? false)

function fetchLead(reloadComponentSlug: string = '') {
    isFetchingLead.value = (reloadComponentSlug === '')

    LeadService.show(userId)
        .then(({ data: { data } }) => {
            user.value = data
            getStats(data.leads[0].id)

            configureSubMenu()
            setActiveComponent(reloadComponentSlug)
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        })
        .finally(() => {
            isFetchingLead.value = false
        })
}

function getStats(leadId:number) {
    LeadService.stats(leadId).then((res)=>{
        stats.value.totalCustomers = res.data.totalCustomers;
    }).catch(()=> {

    })
}

function setActiveComponent(menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

function configureSubMenu() {
    subMenus.value = [
        {
            title: 'Customers',
            component: defineAsyncComponent(
                () => import('@/pages/users/leads/LeadCustomer.vue')
            ),
            slug: 'customer-personal',
            props: { leadId: user.value?.leads[0].id, user: user}
        },
        {
            title: 'Dependents',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserDependent.vue')
            ),
            slug: 'customer-dependents',
            props: {
                dependents: user.value?.dependents,
                userId: userId,
                isUserDeleted,
                slug: 'customer-dependents'
            }
        },
        {
            title: 'Addresses',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/address/AddressIndex.vue')
            )),
            slug: 'customer-address',
            props: {
                addresses: user.value?.addresses,
                userId: userId,
                isUserDeleted,
                componentSlug: 'customer-address'
            }
        },
        {
            title: 'Devices',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserDevice.vue')
            ),
            slug: 'customer-device',
            props: {
                devices: user.value?.devices
            }
        },
        {
            title: 'Orders',
            component: defineAsyncComponent(
                () => import('@/pages/users/customers/CustomerOrder.vue')
            ),
            slug: 'customer-order',
            props: {
                userId: userId
            }
        },
        {
            title: 'Loyalty Points',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/loyalty-points/LoyaltyPointIndex.vue')
            )),
            slug: 'customer-loyalty-point',
            props: {
                userId,
                isUserDeleted
            }
        },
        {
            title: 'Agreements',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/UserAgreement.vue')
            )),
            slug: 'customer-agreements',
            props: {
                userAgreements: user.value?.userAgreements
            }
        },
        {
            title: 'Notifications',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/UserNotification.vue')
            )),
            slug: 'customer-notifications',
            props: {
                userId
            }
        },
        {
            title: 'Announcement',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/UserAnnouncement.vue')
            )),
            slug: 'customer-announcement',
            props: {
                userId
            }
        },
    ]
}
</script>