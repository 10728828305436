<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-end">
                <div class=" ms-auto me-2">
                    <router-link class="btn btn-primary " :to="{ name: 'voucher-create' }">
                        <icon name="plus" />
                        Add New Voucher
                    </router-link>
                </div>
                <div class="dropdown position-static">
                    <button type="button" class="btn dropdown-toggle text-muted" data-bs-toggle="dropdown">
                        <icon name="dots" class="text-secondary" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" style="width: 164px;">
                        <router-link class="dropdown-item text-primary " :to="{ name: 'voucher-bulk-generate' }">
                            <icon name="plus" />
                            Bulk Generate Vouchers
                        </router-link> 
                        <router-link class="dropdown-item text-primary "
                                     :to="{ name: 'shop-voucher-template-index' }"
                        >
                            <icon name="plus"/>
                            Manage Shop Vouchers
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="border rounded-3 p-4 p-md-4 my-3">
                <div class="row g-3">
                    <!-- Voucher Name -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Name" />
                        <input id="voucherName" class="form-control" type="text" v-model="inputFilter.name"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- Voucher Description -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Description" />
                        <input id="voucherDesc" class="form-control" type="text" v-model="inputFilter.description"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- Voucher Code -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Code" />
                        <input id="voucherCode" class="form-control" type="text" v-model="inputFilter.code"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- Email -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Owner's Email" />
                        <input id="voucherEmail" class="form-control" type="email" v-model="inputFilter.email"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- Start Date Range -->
                    <div class="col-12 col-md-4">
                        <label class="form-label">Start Date Range</label>
                        <DateRangePicker input-id="startDateRange" placeholder="Select start date range"
                            :initial-from-date="inputFilter.start_date_from"
                            :initial-to-date="inputFilter.start_date_to" @update:date-range="updateStartDateRange" />
                    </div>

                    <!-- Expiry Date Range -->
                    <div class="col-12 col-md-4">
                        <label class="form-label">Expiry Date Range</label>
                        <DateRangePicker input-id="expiryDateRange" placeholder="Select expiry date range"
                            :initial-from-date="inputFilter.expiry_date_from"
                            :initial-to-date="inputFilter.expiry_date_to" @update:date-range="updateExpiryDateRange" />
                    </div>

                    <!-- Created Date Range -->
                    <div class="col-12 col-md-4">
                        <label class="form-label">Created Date Range</label>
                        <DateRangePicker input-id="createdDateRange" placeholder="Select created date range"
                            :initial-from-date="inputFilter.created_at_from"
                            :initial-to-date="inputFilter.created_at_to" @update:date-range="updateCreatedDateRange" />
                    </div>

                    <!-- Action Buttons -->
                    <div class="d-inline-flex justify-content-end gap-2 mt-3 ms-auto">
                        <div>
                            <ButtonFilter @reset="resetFilter" @filter="fetchVouchers" :is-loading="isFetching" />
                        </div>
                    </div>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Discount</th>
                            <th>User</th>
                            <th>Started Date</th>
                            <th>Expired Date</th>
                            <th>Created At</th>
                            <th>Used</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="vouchers.length > 0">
                        <tr v-for="voucher in vouchers" :key="voucher.id">
                            <td>
                                <p class="mb-0">{{ voucher.name }}</p>
                                <p class="text-secondary mb-0" v-if="voucher.description">{{ voucher.description }}</p>
                            </td>
                            <td>{{ voucher.code }}</td>
                            <td>{{ getDiscountAmountDisplay(voucher.discountAmount, voucher.isFixedDiscount) }}</td>
                            <td>{{ voucher.user?.email }}</td>
                            <td>{{ voucher.startedAt ? formatDate(voucher.startedAt, 'DD-MMM-YYYY') : '-' }}</td>
                            <td>{{ voucher.expiredAt ? formatDate(voucher.expiredAt, 'DD-MMM-YYYY') : '-' }}</td>
                            <td>{{ formatDate(voucher.createdAt, 'DD-MMM-YYYY') }}</td>
                            <td>{{ getUsageDisplay(voucher.extraAttributes) }}</td>
                            <td class="text-center">
                                <div class="btn-group">
                                    <button class="btn btn-icon btn-info" @click.prevent="sendVoucherClicked(voucher)"
                                        :disabled="!voucher.wooCouponOrder">
                                        <icon name="mail" />
                                    </button>
                                    <router-link class="btn btn-icon btn-primary"
                                        :to="{ name: 'voucher-show', params: { voucherId: voucher.id } }">
                                        <icon name="eye" />
                                    </router-link>
                                    <router-link class="btn btn-icon btn-success"
                                        :to="{ name: 'voucher-edit', params: { voucherId: voucher.id } }">
                                        <icon name="pencil" />
                                    </router-link>
                                    <button class="btn btn-icon btn-danger" @click="deleteVoucher(voucher)">
                                        <icon name="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetching" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import ButtonFilter from '@/components/ButtonFilter.vue'
import DateRangePicker from '@/components/form/DateRangePicker.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import TablePagination from '@/components/TablePagination.vue'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import {
    EMAIL_FAILED,
    EMAIL_SUCCESS,
    getDiscountAmountDisplay,
    getUsageDisplay,
    sendEmailCode
} from '@/composable/useVoucher'
import VoucherService from '@/services/settings/VoucherService'
import { useNotificationsStore } from '@/stores/notifications'
import UserFilter from '@/types/Filter'
import VoucherQuery from '@/types/query/VoucherQuery'
import Voucher from '@/types/Voucher'
import { onMounted, ref, watch } from 'vue'

const { addToastNotification } = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()

const vouchers = ref<Array<Voucher>>([])

const inputFilter = ref({
    name: '',
    description: '',
    code: '',
    email: '',
    start_date_from: '',
    start_date_to: '',
    expiry_date_from: '',
    expiry_date_to: '',
    created_at_from: '',
    created_at_to: ''
})


const isFetching = ref<boolean>(false)
const setIsFetching = (value = true) => isFetching.value = value

onMounted(() => {
    fetchVouchers()
})

watch(
    () => metaPageTriggered.value,
    () => {
        fetchVouchers()
    }
)

const updateStartDateRange = (dateRange: { from_date: string; to_date: string }) => {
    inputFilter.value.start_date_from = dateRange.from_date
    inputFilter.value.start_date_to = dateRange.to_date
}

const updateExpiryDateRange = (dateRange: { from_date: string; to_date: string }) => {
    inputFilter.value.expiry_date_from = dateRange.from_date
    inputFilter.value.expiry_date_to = dateRange.to_date
}

const updateCreatedDateRange = (dateRange: { from_date: string; to_date: string }) => {
    inputFilter.value.created_at_from = dateRange.from_date
    inputFilter.value.created_at_to = dateRange.to_date
}

const resetFilter = () => {
    inputFilter.value = {
        name: '',
        description: '',
        code: '',
        email: '',
        start_date_from: '',
        start_date_to: '',
        expiry_date_from: '',
        expiry_date_to: '',
        created_at_from: '',
        created_at_to: ''
    }

    fetchVouchers()
}

const fetchVouchers = async () => {
    setIsFetching()

    const query: VoucherQuery = {
        perPage: metaPage.value.perPage,
        page: metaPage.value.currentPage,
        name: inputFilter.value?.name,
        description: inputFilter.value?.description,
        code: inputFilter.value?.code,
        user_email: inputFilter.value?.email,
        started_at_from: inputFilter.value?.start_date_from,
        started_at_to: inputFilter.value?.start_date_to,
        expired_at_from: inputFilter.value?.expiry_date_from,
        expired_at_to: inputFilter.value?.expiry_date_to,
        created_at_from: inputFilter.value?.created_at_from,
        created_at_to: inputFilter.value?.created_at_to
    }


    try {
        const { data: { data, meta } } = await VoucherService.index(query)
        vouchers.value = data
        updateMetaPage(meta)
    } catch (e) {
        addToastNotification({
            message: 'Failed to fetch vouchers due to internal server error. Please contact tech team.',
            type: 'danger'
        })
    }

    setIsFetching(false)
}

const sendVoucherClicked = async (voucher: Voucher) => {
    const result = await sendEmailCode(voucher)

    if (result === EMAIL_SUCCESS) {
        addToastNotification({
            message: 'Voucher code has been emailed to customer',
            type: 'success',
        })
    } else if (result === EMAIL_FAILED) {
        addToastNotification({
            message: 'Error when sending email. Please try again.',
            type: 'danger',
        })
    }
}

const deleteVoucher = (voucher: Voucher) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete voucher ${voucher.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then(async (result: any) => {
        if (!result.isConfirmed) {
            return
        }

        try {
            setIsFetching()
            await VoucherService.destroy(voucher.id)
            setIsFetching(false)

            addToastNotification({
                message: `Voucher ${voucher.name} is successfully deleted.`,
                type: 'success'
            })

            fetchVouchers()
        } catch {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    })
}

</script>
