<template>

    <!-- MODE: Edit -->
    <template v-if="isEditingAmount">
        <tr>

            <td class="py-1 px-1 w-50">
                <VueSelect :options="sessionRateAmountsOrderable" v-model="rate.type" placeholder="Select rate type"
                    label="name" required class="w-100" />
            </td>
            <td class="text-end px-1">
                <input v-model.number="rate.quantity" class="mx-1" :disabled="shouldDisableEditQuantity" />
            </td>
            <td class="text-end px-1 ">
                <input :value="rate.amount / 100" @input="($event) => rate.amount = $event.target.value * 100"
                    class="mx-1" :disabled="shouldDisableEditAmount" />
            </td>
            <td class="text-end px-1 w-4">
                <button v-if="canSave" @click.prevent="save" title="Confirm" class="btn btn-sm btn-success btn-icon">
                    <icon name="check" />
                </button>
                <button v-else @click.prevent="remove" title="Remove" class="btn btn-sm btn-danger btn-icon">
                    <icon name="x" />
                </button>
            </td>
        </tr>

        <tr v-if="rate.type?.type === SessionRateAmountTypeEnum.TYPE_OTHERS" class="w-100">
            <td colspan="3" class="py-1">
                <FormLabel label="Description" class="px-1" />
                <input type="text" v-model="rate.custom_description" class="form-control mx-1 w-100" />
            </td>
        </tr>

    </template>

    <!-- MODE: Display -->
    <template v-else>
        <tr class="border-top">
            <td class="py-1 px-1 w-50">
                {{ rateName }}
            </td>
            <td class="text-end px-1 ">
                {{ rate.quantity }} unit(s)
            </td>
            <td class="text-end px-1 ">
                {{ toMYR(rate.amount) }}
            </td>
            <td class="text-end px-1 w-5 ">
                <button @click.prevent="toggleEditAmount" title="Edit" class="btn btn-sm btn-icon">
                    <icon name="edit" />
                </button>
            </td>
        </tr>
    </template>
</template>

<script setup lang="ts">

import VueSelect from 'vue-select'
import { storeToRefs } from 'pinia'
import { useCreateOrderStore } from '@/stores/createOrder'
import { useResourcesStore } from '@/stores/resources'
import { computed, ref, watch } from 'vue'
import { CustomRate } from '@/types/formData/CustomRate'
import { toMYR } from '@/composable/useCurrency'
import { SessionRateAmountTypeEnum } from '@/enums/SessionRateAmountTypeEnum'
import FormLabel from '@/components/form/FormLabel.vue'

const createOrderStore = useCreateOrderStore()
const { generatePreview, removeSelectedRate } = createOrderStore
const { form } = storeToRefs(createOrderStore)
const { sessionRateAmountsOrderable } = storeToRefs(useResourcesStore())

const props = defineProps({
    sessionId: {
        type: Number,
        required: true
    },
    index: {
        type: Number,
        required: true
    }
})

const rateName = computed(() => {
    return rate.value.type?.type === SessionRateAmountTypeEnum.TYPE_OTHERS
        ? `Others - ${rate.value?.custom_description}`
        : rate.value.type?.name
})

const isEditingAmount = ref(true)
const toggleEditAmount = () => isEditingAmount.value = !isEditingAmount.value

const rate = computed<CustomRate>(() => form.value.additional_rates[props.sessionId][props.index])

const shouldDisableEditQuantity = computed(() => !rate.value?.type || rate.value?.type?.is_fixed_quantity)
const shouldDisableEditAmount = computed(() => !rate.value?.type || rate.value?.type?.is_fixed_amount)

const canSave = computed(() => {
    return !!rate.value.type
        && !!rate.value.quantity
        && !!rate.value.amount
})

const save = async () => {
    await generatePreview()
    toggleEditAmount()
}

const remove = async () => {
    removeSelectedRate(props.sessionId, props.index)
    await generatePreview()
    toggleEditAmount()
}

const calculateTotalRate = () => {

    if (!rate.value.type || !rate.value.quantity) {
        rate.value.amount = 0
        return
    }

    const amount = rate.value.type?.amount * rate.value.quantity

    rate.value.amount = Number((amount).toFixed(2))
}

watch(() => rate.value.type, calculateTotalRate)
watch(() => rate.value.quantity, calculateTotalRate)
</script>