<template>
    <router-link :to="{ 'name': 'carer-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left" />
        <span>Back to Carers</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingCarer" v-if="!user" />
    <template v-else>
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col-auto position-relative">
                    <img :src="user.avatarUrl" alt="" class="avatar avatar-xl rounded-circle" @error="fallbackAvatar"
                        loading="lazy" />

                    <!-- Open photo edit modal -->
                    <button class="btn btn-primary btn-icon rounded-circle position-absolute"
                        style="bottom: 0; right: 0;" data-bs-toggle="modal" data-bs-target="#modal-edit-avatar"
                        @click.prevent="modalForm = user">
                        <icon name="pencil" />
                    </button>
                </div>
                <div class="col">
                    <div class="d-flex">
                        <h2 class="page-title">{{ profile?.fullName ?? user.name }}</h2>
                        <div class="align-self-center ms-2">
                            <status-badge :show="user.isDeleted" label="Deleted" color="gray"></status-badge>
                            <status-badge :show="user.isBanned" label="Banned" color="red"></status-badge>
                        </div>
                    </div>
                    <div class="page-subtitle">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <icon name="id" :stroke-width="1.2" class="pe-2" />
                                <span class="text-reset">{{ profile?.onlineId ?? 'N/A' }}</span>
                            </div>
                            <div class="col-auto">
                                <icon name="mail" :stroke-width="1.2" class="pe-2" />
                                <span class="text-reset">{{ user.email }}</span> <span v-if="user.emailVerifiedAt"
                                    class="text-green">
                                    <icon name="circle-check" />
                                </span>
                            </div>
                            <div class="col-auto">
                                <icon name="phone" :stroke-width="1.2" class="pe-2" />
                                <span class="text-reset">{{ profile?.mobileNo }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto d-md-flex space-x-2">
                    <CarerState :user="user" />

                    <div class="dropdown position-static">
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                            :disabled="user.isDeleted">
                            Action
                        </button>
                        <div class="dropdown-menu dropdown-menu-end cursor-pointer" style="width: 240px;">
                            <a class="dropdown-item" @click.prevent="sendResetPasswordLink">
                                <icon name="password" class="me-2" />Send Reset Password Link
                            </a>
                            <a v-if="!user.emailVerifiedAt" class="dropdown-item"
                                @click.prevent="sendVerificationCode(user)">
                                <icon name="mail-forward" class="me-2" />
                                Send Email Verification Code
                            </a>
                            <a class="dropdown-item" @click.prevent="modalDeactivateUser?.show()">
                                <icon name="ban" class="me-2" />Deactivate carer
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cards mt-2 mb-3">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Job Done</h4>
                                <h1 class="fw-normal mb-0">{{ stats.totalCompletedJobs }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="subtask" :size="32" :stroke-width="1.2" class="text-blue" />
                            </span>
                        </div>
                    </div>
                    <overlay-spinner :is-showing="isFetchingStats" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Hours</h4>
                                <h1 class="fw-normal mb-0">{{ stats.totalHours }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="clock" :size="32" :stroke-width="1.2" class="text-blue" />
                            </span>
                        </div>
                    </div>
                    <overlay-spinner :is-showing="isFetchingStats" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Payouts</h4>
                                <h1 class="fw-normal mb-0">{{ toMYR(stats.totalPayouts) }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="cash" :size="32" :stroke-width="1.2" class="text-blue" />
                            </span>
                        </div>
                    </div>
                    <overlay-spinner :is-showing="isFetchingStats" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Rating Overall</h4>
                                <div class="d-flex">
                                    <star-rating :rating="stats.rating ?? 0" />
                                    <h1 class="fw-normal mb-0 mx-2">({{ stats.rating?.toFixed(2) ?? 'N/A' }})</h1>
                                </div>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="stars" :size="32" :stroke-width="1.2" class="text-blue" />
                            </span>
                        </div>
                    </div>
                    <overlay-spinner :is-showing="isFetchingStats" />
                </div>
            </div>
        </div>

        <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

        <div class="py-4">
            <KeepAlive>
                <component :is="activeComponent.component" @deleted="fetchCarer" @updated-or-created="fetchCarer"
                    v-bind="activeComponent.props" :key="`${activeComponent.slug}-${Date.now()}`">
                </component>
            </KeepAlive>
        </div>
    </template>

    <modal-deactivate-user user-type="carer" :user-id="userId" :carer-id="user?.carer?.id"
        :modal="modalDeactivateUser"></modal-deactivate-user>

    <carer-avatar-edit-modal :user="user" @updated="fetchCarer" />
    <kc-modal modal-id="modal-ban-carer" modal-size="md">
        <div class="modal-header">
            <h4 class="modal-title">Ban Carer</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body bg-white">
            <form id="formBanCarer" @submit.prevent="updateBanCarer">
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Specify ban reason</label>
                    <div class="col">
                        <textarea class="form-control" name="ban_reason" rows="5" v-model="banReason"
                            maxlength="250"></textarea>
                    </div>
                    <span class="text-end">{{ banReason?.length ?? 0 }} / 250 </span>
                </div>
            </form>
        </div>

        <div class="modal-footer pt-2 border-top">
            <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="submit" form="formBanCarer">
                <span>Save</span>
            </button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import StatusBadge from '@/components/StatusBadge.vue'
import { fallbackAvatar } from '@/composable/profile'
import { toMYR } from '@/composable/useCurrency'
import { useEmailVerification } from '@/composable/useEmailVerification'
import { BunnyNetStatus } from '@/enums/BunnyNetStatusEnum'
import CarerAvatarEditModal from "@/pages/users/carers/CarerAvatarEditModal.vue"
import ModalDeactivateUser from '@/pages/users/ModalDeactivateUser.vue'
import AuthService from '@/services/AuthService'
import CarerService from '@/services/users/CarerService'
import { useNotificationsStore } from '@/stores/notifications'
import User from '@/types/User'
import UserProfile from '@/types/UserProfile'
import Video from '@/types/Video'
import Modal from 'bootstrap/js/dist/modal'
import { computed, defineAsyncComponent, markRaw, onMounted, provide, ref } from 'vue'
import { useRoute } from 'vue-router'
import CarerState from './CarerState.vue'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const userId = Number(route.params.userId)
const isFetchingCarer = ref<boolean>(false)
const isFetchingStats = ref<boolean>(false)
const modalForm = ref<any>({})
const user = ref<User>()
const stats = ref<Record<string, any>>({
    totalCompletedJobs: 0,
    totalHours: 0,
    totalPayouts: 0,
    rating: 0,
})

const { sendVerificationCode } = useEmailVerification()

const activeComponent = ref<any>({})
const subMenus = ref<Array<any>>([])
const carerState = ref<string>()
const modalBanCarer = ref<Modal>()
const modalDeactivateUser = ref<Modal>()
const banReason = ref<string | undefined>('')

provide('setActiveComponent', setActiveComponent)

fetchStats()
fetchCarer()

const isUserDeleted = computed((): boolean => user.value?.isDeleted ?? false)

onMounted(() => {
    modalBanCarer.value = new Modal('#modal-ban-carer')
    modalDeactivateUser.value = new Modal('#modal-deactivate-user')
})

const profile = computed((): UserProfile | undefined => {
    return user.value?.mainProfile
})

function setActiveComponent(menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

const configureSubMenu = () => {
    subMenus.value = [
        {
            title: 'Personal',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerPersonal.vue')
            ),
            slug: 'carer-personal',
            props: { user },
        },
        {
            title: 'Dependents',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserDependent.vue')
            ),
            slug: 'carer-dependents',
            props: {
                dependents: user.value?.dependents,
                userId,
                isUserDeleted,
                slug: 'carer-dependents',
            },
        },
        {
            title: 'Addresses',
            component: defineAsyncComponent(
                () => import('@/pages/address/AddressIndex.vue')
            ),
            slug: 'carer-address',
            props: {
                addresses: user.value?.addresses,
                userId: user.value?.id,
                isUserDeleted,
                isCarer: true,
                componentSlug: 'carer-address'
            },
        },
        {
            title: 'Onboarding',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/carers/CarerOnboarding.vue')
            )),
            slug: 'carer-onboarding',
            props: {
                carerOnboardings: user.value?.carerOnboardings
            },
        },
        {
            title: 'Assessments',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerAssessment.vue')
            ),
            slug: 'carer-assessment',
            props: {
                introQuizStatus: user.value?.carer?.introQuizStatus,
                assessmentAnswers: user.value?.carer?.assessmentAnswers,
                userId: user.value?.id,
                componentSlug: 'carer-assessment'
            },
        },
        {
            title: 'Sessions',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserSession.vue')
            ),
            slug: 'carer-session',
            props: {
                carerUserId: userId,
            },
        },
        {
            title: 'Applied Sessions',
            slug: 'carer-applied-sessions',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerAppliedSession.vue')
            ),
            props: { userId },
        },
        {
            title: 'Payouts',
            slug: 'carer-payouts',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerPayout.vue')
            ),
            props: { userId },
        },
        {
            title: 'Bonus & Penalty',
            slug: 'carer-bonus-penalty',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerBonusPenalty.vue')
            ),
            props: {
                userId,
                isUserDeleted,
            }
        },
        {
            title: 'Reviews',
            slug: 'carer-reviews',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerReview.vue')
            ),
            props: {
                userId,
                isUserDeleted,
            },
        },
        {
            title: 'Documents',
            slug: 'carer-documents',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerDocument.vue')
            ),
            props: {
                carerId: user.value?.carer?.id,
                isUserDeleted,
                documents: user.value?.carer?.documents
            },
        },
        {
            title: 'Videos',
            slug: 'carer-videos',
            component: defineAsyncComponent(
                () => import('@/pages/users/carers/CarerVideo.vue')
            ),
            props: {
                userId: user.value?.id,
                isUserDeleted,
                videos: user.value?.videos
            },
        },
        {
            title: 'Devices',
            slug: 'carer-devices',
            component: defineAsyncComponent(
                () => import('@/pages/users/UserDevice.vue')
            ),
            props: { devices: user.value?.devices },
        },
        {
            title: 'Loyalty Points',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/loyalty-points/LoyaltyPointIndex.vue')
            )),
            slug: 'carer-loyalty-points',
            props: {
                userId,
                isUserDeleted,
            },
        },
        {
            title: 'Agreements',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/UserAgreement.vue')
            )),
            slug: 'carer-agreements',
            props: {
                userAgreements: user.value?.userAgreements,
            }
        },
        {
            title: 'Notifications',
            component: markRaw(defineAsyncComponent(
                () => import('@/pages/users/UserNotification.vue')
            )),
            slug: 'carer-notifications',
            props: {
                userId,
            },
        },
    ]
}

function fetchCarer(reloadComponentSlug: string = '') {
    isFetchingCarer.value = (reloadComponentSlug === '') // this will partially reload the async component

    CarerService.show(userId)
        .then(({ data: { data } }) => {
            user.value = data
            carerState.value = user.value?.carer?.state ?? 'N/A'
            banReason.value = user.value?.banReason
            configureSubMenu()
            setActiveComponent(reloadComponentSlug)
        }).catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        }).finally(() => isFetchingCarer.value = false)
}

function fetchStats() {
    isFetchingStats.value = true

    CarerService.getStats(userId)
        .then(({ data }) => {
            stats.value = data
        }).catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        }).finally(() => isFetchingStats.value = false)
}

const updateBanCarer = () => {
    if (!user.value?.carer) {
        return
    }

    const data = {
        state: 'Banned',
        ban_reason: banReason.value,
    }

    CarerService.updateState(user.value.carer.id, data)
        .then(() => {
            carerState.value = data.state

            addToastNotification({
                message: 'Carer has been banned',
                type: 'success',
            })
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => {
            fetchCarer()
            modalBanCarer.value?.hide()
        })
}


const sendResetPasswordLink = () => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to send reset password link to ${user.value?.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            AuthService.forgotPassword({ email: user.value?.email })
                .then(() => {
                    addToastNotification({
                        message: `Password reset link has been sent to carer email`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message: 'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
                .finally(() => { })
        }
    })
}

window.Echo.private('backoffice')
    .listen('.VideoCreated', ({ model }: any) => {
        if (user.value) {
            const videoIndex = user.value.videos.findIndex((video: Video) => video.id === model.id)
            if (videoIndex !== -1) {
                user.value.videos[videoIndex] = model
            } else {
                user.value.videos.push(model)
            }
        }
    })
    .listen('.VideoUpdated', ({ model }: any) => {
        if (user.value) {
            const videoIndex = user.value.videos.findIndex((video: Video) => video.id === model.id)
            if (videoIndex !== -1) {
                user.value.videos[videoIndex] = model
                if ([BunnyNetStatus.STATUS_FINISHED].includes(model.status)) {
                    addToastNotification({
                        message: `Video ${model.label} upload has been completed`,
                        type: 'success',
                    })
                }
            }
        }
    })

</script>
