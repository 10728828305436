<template>
    <template v-if="activeComponent !== null && subMenus.length">
        <sub-menu :items="subMenus" :active="activeComponent" />
        <div class="py-4">
            <KeepAlive>
                <component :is="activeComponent.component"
                    v-bind="activeComponent.props"
                    :key="`${activeComponent.slug}-${Date.now()}`">
                </component>
            </KeepAlive>
        </div>
    </template>
    <template v-else>
        <div>
            <overlay-spinner :is-showing="isLoading"></overlay-spinner>
        </div>
    </template>
</template>

<script setup lang="ts">
import { defineAsyncComponent, provide, ref } from 'vue'
import SubMenu from '@/components/SubMenu.vue'
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'
import PlatformContentType from '@/types/PlatformContentType'
import PlatformContentTypeService from '@/services/PlatformContentTypeService'
import { useNotificationsStore } from '@/stores/notifications'
import { useRoute } from 'vue-router'
import { PlatformContentTypeOfTypeEnum } from '@/enums/PlatformContentTypeOfTypeEnum'

const route = useRoute()

provide('setActiveComponent', setActiveComponent)

const { platformContentTypes } = storeToRefs(useResourcesStore())
const { addToastNotification } = useNotificationsStore()

const contentTypes = ref<Array<PlatformContentType>>([])
const isLoading = ref<boolean>(false)

const activeComponent = ref<object>({
    component: '',
    props: '',
    slug: ''
})
const subMenus = ref<Array<any>>([])

function setActiveComponent (menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

function configureSubMenu () {
    subMenus.value = contentTypes.value.map((contentType: any) => ({
        title: contentType.name,
        component: defineAsyncComponent(
            () => import('./ContentIndex.vue')
        ),
        slug: `content-type-${contentType.id}`,
        props: {
            header: contentType.name,
            contentTypeId: contentType.id,
            mediaPresetId: contentType.media_preset_id,
            mediaPreset: contentType.mediaPreset
        },
    }))
}

const fetchContentTypes = async () => {
    let type = route.query.type ?? PlatformContentTypeOfTypeEnum.TYPE_DASHBOARD

    isLoading.value = true

    try {
        const response = await PlatformContentTypeService.index({ type: type })
        contentTypes.value = response.data.data
        configureSubMenu()
        setActiveComponent('')
    } catch {
        addToastNotification({
            type: 'danger',
            message: 'Internal server error. Please contach tech team.'
        })
    } finally {
        isLoading.value = false
    }
}

fetchContentTypes()
</script>
