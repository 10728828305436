<template>
    <kc-modal modal-id="content-media-presets-form-modal" :modal-keyboard="false" :modal-title="modalTitle">
        <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': v$.inputUser.name.$error }" v-model="inputUser.name"
                @input="clearError" />
            <div class="invalid-feedback">{{ v$.inputUser.name.$errors[0]?.$message }}</div>
        </div>
        <div class="mb-3">
            <label class="form-label">Width <small>(px)</small></label>
            <input type="number" class="form-control" :class="{ 'is-invalid': v$.inputUser.width.$error }" v-model="inputUser.width"
                @input="clearError"/>
            <div class="invalid-feedback">{{ v$.inputUser.width.$errors[0]?.$message }}</div>
        </div>
        <div class="mb-3">
            <label class="form-label">Height <small>(px)</small></label>
            <input type="number" class="form-control" :class="{ 'is-invalid': v$.inputUser.height.$error }" v-model="inputUser.height"
                @input="clearError"/>
            <div class="invalid-feedback">{{ v$.inputUser.height.$errors[0]?.$message }}</div>
        </div>
        <div class="mb-3">
            <label class="form-label">Width Setting</label>
                <vue-select 
                    :options="mediaWidthSettings"
                    label="name"
                    v-model="inputUser.width_setting"
                    :class="{ 'is-invalid': v$.inputUser?.width_setting?.$error }"
                    @change="clearError" 
                    :reduce="(widthSetting: any) => widthSetting.value"
                    placeholder="Width Setting"
                />
            <div class="invalid-feedback">{{ v$.inputUser?.width_setting?.$errors[0]?.$message }}</div>
        </div>
        <template #footer>
            <button type="button" id="close-content-media-presets-form-modal-button" class="btn btn-link link-secondary"
                data-bs-dismiss="modal">
                Close
            </button>
            <button class="btn btn-primary ms-auto" :class="{ disabled: isSaving }" type="submit" @click="save">
                <span v-if="isSaving" class="spinner-border spinner-border-sm me-2" role="status"></span>
                {{ isSaving ? 'Saving' : 'Save' }}
            </button>
        </template>
    </kc-modal>
</template>

<script setup lang="ts">
import { MediaWidthSetting } from '@/enums/MediaWidthSetting'
import PlatformContentMediaPresetService from '@/services/PlatformContentMediaPresetService'
import { useNotificationsStore } from '@/stores/notifications'
import ErrorBag from '@/types/ErrorBag'
import OptionType from '@/types/OptionType'
import PlatformContentMediaPreset from '@/types/PlatformContentMediaPreset'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { AxiosError } from 'axios'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{
    mediaPreset?: PlatformContentMediaPreset
}>()

const emit = defineEmits<{
    (e: 'save'): void
}>()

const { addToastNotification } = useNotificationsStore()

const inputUser = ref<{
    name: string
    width_setting: string
    width: number
    height: number
}>({
    name: '',
    width_setting: MediaWidthSetting.DEFAULT,
    width: 0,
    height: 0,
})
const isSaving = ref<boolean>(false)
const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

const mode = computed(() => props.mediaPreset ? 'updated' : 'created')
const modalTitle = computed(() => mode.value === 'created' ? 'Add New Media Preset' : 'Edit Media Preset')
const notificationMessage = computed(() => `${inputUser.value.name} is successfully ${mode.value}`)

const rules = computed(() => ({
    inputUser: {
        name: {
            required: helpers.withMessage(
                'The name field is required',
                required
            )
        },
        width: {
            required: helpers.withMessage(
                'The width field is required',
                required
            )
        },
        height: {
            required: helpers.withMessage(
                'The height field is required',
                required
            )
        },
    }
}))

const v$ = useVuelidate(rules, { inputUser }, { $scope: false, $externalResults })

const save = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (!validated) return

    isSaving.value = true

    try {
        switch (mode.value) {
            case 'created':
                await PlatformContentMediaPresetService.store(inputUser.value)
                break
            default:
                if (props.mediaPreset === undefined) {
                    addToastNotification({
                        type: 'danger',
                        message: 'Internal server error. Please contach tech team.'
                    })

                    return
                }

                await PlatformContentMediaPresetService.update(props.mediaPreset.id, inputUser.value)
                break
        }

        document.getElementById('close-content-media-presets-form-modal-button')?.click()

        emit('save')

        addToastNotification({
            type: 'success',
            message: notificationMessage.value
        })
    } catch (error) {
        handleError(error)
    }

    isSaving.value = false
}

const handleError = (error: any) => {
    if (error instanceof AxiosError && error.response && error.response.status === 422) {
        Object.assign($externalResults.value.inputUser, error.response.data.errors)
    } else {
        addToastNotification({
            type: 'danger',
            message: 'Internal server error. Please contach tech team.'
        })
    }
}

const clearError = () => {
    $externalResults.value.inputUser = {}
}

const mediaWidthSettings: OptionType[] = [
    { name: 'Default', value: MediaWidthSetting.DEFAULT },
    { name: 'Half', value: MediaWidthSetting.HALF },
    { name: 'Peek', value: MediaWidthSetting.PEEK },
    { name: 'Full', value: MediaWidthSetting.FULL },
    { name: 'Maximum', value: MediaWidthSetting.MAXIMUM },
]

onMounted(() => {
    document.getElementById('content-media-presets-form-modal')?.addEventListener('hidden.bs.modal', () => {
        v$.value.$reset()
        inputUser.value.name = props.mediaPreset?.name ?? ''
        inputUser.value.width_setting = props.mediaPreset?.preset?.width_setting ?? MediaWidthSetting.DEFAULT
        inputUser.value.width = props.mediaPreset?.preset?.width ?? 0
        inputUser.value.height = props.mediaPreset?.preset?.height ?? 0
    })
})

watch(
    () => props.mediaPreset,
    (mediaPreset) => {
        inputUser.value.name = mediaPreset?.name ?? ''
        inputUser.value.width_setting = mediaPreset?.preset?.width_setting ?? MediaWidthSetting.DEFAULT
        inputUser.value.width = mediaPreset?.preset?.width ?? 0
        inputUser.value.height = mediaPreset?.preset?.height ?? 0
    }
)

</script>