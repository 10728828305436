<template>
    <kc-modal modal-id="modal-update-carer-rating" modal-size="xl">
        <div class="modal-header">
            <h4 class="modal-title"> {{ sessionNo }} </h4>
            <button id="btn-modal-update-carer-rating-close" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body bg-white">
            <div class="col">
                <!--  Star Rating  -->
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">
                        Rating
                    </label>
                    <div class="col">
                        <star-rating :rating="rating"></star-rating>
                    </div>
                </div>
                <div v-for="(answer, index) in inputUser.review_answers" :key="index" class="form-group mb-3 row">
                    <label class="form-label col-form-label">
                        {{ getQuestion(answer.review_question_id)?.content }}
                    </label>
                    <div class="col">
                        <div class="row pl-3">
                            <label class="col-2 form-check form-check-inline">
                                <input class="form-check-input" type="radio"
                                       v-model="inputUser.review_answers[index].content"
                                       :value="true"
                                >
                                <span class="form-check-label text-capitalize">Yes</span>
                            </label>
                            <label class="col-3 form-check form-check-inline">
                                <input class="form-check-input" type="radio"
                                       v-model="inputUser.review_answers[index].content"
                                       :value="false"
                                       :class="{ 'is-invalid': hasError(`answers.${answer.review_question_id}`) }"
                                >
                                <span class="form-check-label text-capitalize">No</span>
                            </label>
                        </div>

                        <error-message :has-error="hasError(`answers.${answer.review_question_id}`)"
                                       :message="getError(`answers.${answer.review_question_id}`)"
                        ></error-message>
                    </div>
                </div>

                <div class="form-group mb-3 row align-items-center">
                    <label class="form-label col-3 col-form-label">Mark as {{inputUser.is_completed ? 'completed' : 'draft'}}</label>
                    <div class="col">
                        <div class="form-check form-switch ">
                            <input class="form-control form-check-input"
                                   type="checkbox"
                                   :class="{ 'is-invalid': hasError('isCompleted') }"
                                   v-model="inputUser.is_completed" :checked="inputUser.is_completed">
                        </div>
                        <error-message :has-error="hasError('isCompleted')"
                                       :message="getError('isCompleted')"></error-message>
                    </div>
                </div>
                <div class="form-group mb-3 row align-items-center">
                    <label class="form-label col-3 col-form-label">{{inputUser.is_public_displayed ? 'Display to' : 'Hide from'}} public</label>
                    <div class="col">
                        <div class="form-check form-switch ">
                            <input class="form-control form-check-input"
                                   type="checkbox"
                                   :class="{ 'is-invalid': hasError('isPublicDisplayed') }"
                                   v-model="inputUser.is_public_displayed" :checked="inputUser.is_public_displayed">
                        </div>
                        <error-message :has-error="hasError('isPublicDisplayed')"
                                       :message="getError('isPublicDisplayed')"></error-message>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Comment</label>
                    <div class="col">
                        <textarea class="form-control" rows="5" v-model="inputUser.comment"></textarea>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Remarks</label>
                    <div class="col">
                        <textarea class="form-control" rows="5" v-model="inputUser.remarks"></textarea>
                    </div>
                </div>

            </div>
        </div>
        <div class="modal-footer pt-3 border-top">
            <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" @click.prevent="updateCarerRating">Save</button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">

import StarRating from '@/components/StarRating.vue'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import useFormError from '@/composable/useFormError'
import { $externalResults, rules as CarerRatingRule } from '@/rules/CarerRatingRule'
import SessionReviewService from '@/services/SessionReviewService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import UserInput from '@/types/Input'
import ReviewQuestion from '@/types/ReviewQuestion'
import SessionReview from '@/types/SessionReview'
import SessionReviewAnswer from '@/types/SessionReviewAnswer'
import { useVuelidate } from '@vuelidate/core'
import { AxiosError } from 'axios'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

const {addToastNotification} = useNotificationsStore()

const emit = defineEmits(['carerReviewUpdated'])

const props = defineProps({
    sessionNo: {type: String, required: true},
    review: {type: Object as () => SessionReview, required: true}
})

const inputUser = ref<UserInput>({
    rating: 0,
    review_answers: [],
    is_completed: false,
    is_public_displayed: false,
    comment: '',
    remarks: ''
})


const v$ = useVuelidate(CarerRatingRule, {inputUser}, {$externalResults})
const {hasError, getError, clearError, clearAllErrors} = useFormError(v$)

let modalElement: HTMLElement | null = null
let btnCloseModal: HTMLElement | null = null

const {carerReviewQuestions} = storeToRefs(useResourcesStore())

const getQuestion = (id: number) => carerReviewQuestions.value.find((question: ReviewQuestion) => question.id === id)

const rating = computed<number>(() => inputUser.value.review_answers.filter((answer: SessionReviewAnswer) => answer.content).length)

onMounted(() => {

    modalElement = document.getElementById('modal-update-carer-rating')
    btnCloseModal = document.getElementById('btn-modal-update-carer-rating-close')

    if (!modalElement) {
        return
    }

    modalElement?.addEventListener('shown.bs.modal', () => {
        populateForm()
    })


    modalElement?.addEventListener('hidden.bs.modal', () => {
        resetForm()
    })


})

const updateCarerRating = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (!validated) {
        return
    }

    try {
        const payload = {...inputUser.value}
        payload.rating = rating.value

        await SessionReviewService.update(props.review.id, payload)

        addToastNotification({
            message: `Carer review updated`,
            type: 'success',
        })

        btnCloseModal?.click()

        emit('carerReviewUpdated')

    } catch (err) {
        const error = err as AxiosError
        if (error.response?.status === 422) {
            const responseData = error.response.data as { errors?: Record<string, string[]> }
            $externalResults.value.inputUser = responseData.errors || {}
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        }
    }
}


const populateForm = async () => {

    // Deep copy review details
    const review: SessionReview = JSON.parse(JSON.stringify(props.review))

    inputUser.value = {
        id: review.id,
        rating: rating.value,
        review_answers: review.answers.map((answer: SessionReviewAnswer) => ({
            review_question_id: answer.reviewQuestionId,
            content: answer.content,
        })),
        comment: review.comment,
        remarks: review.remarks,
        is_completed: review.isCompleted,
        is_public_displayed: review.isPublicDisplayed,
    }

}

const resetForm = async () => {
    inputUser.value = {
        id: 0,
        rating: 0,
        review_answers: [],
        isCompleted: false,
        isPublicDisplayed: false,
        comment: '',
        remarks: ''
    }
}



</script>