export enum SessionRateAmountTypeEnum {
    TYPE_HOURLY = 1,
    TYPE_EXTRA_CHILDREN_COUNT = 2,
    TYPE_OFF_HOURS = 3,
    TYPE_EXTENSION = 4,
    TYPE_LAST_MINUTE = 5,
    TYPE_OVERNIGHT = 6,
    TYPE_PUBLIC_HOLIDAY = 7,
    TYPE_RAYA_HOLIDAY = 8,
    TYPE_BONUS_50 = 9,
    TYPE_BONUS_100 = 10,
    TYPE_CARER_TRAVEL_INCENTIVE = 11,
    TYPE_CARER_TRANSACTION_BONUS = 12,
    TYPE_CARER_TRANSACTION_PENALTY = 13,
    TYPE_RESCHEDULE = 14,
    TYPE_OTHERS = 15,
}
