import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'

const endpoint = '/backoffice/announcements'

export default {
    index(query: UserQuery = {}) {
        const queryString = encodeURI(Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&'))

        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },

    store(data: object = {}) {
        return httpClientV2.post(endpoint, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    update(id: number, data: object = {}) {
        return httpClientV2.post(`${endpoint}/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    destroy(id: number) {
        return httpClientV2.delete(`${endpoint}/${id}`)
    },

    publish(id: number) {
        return httpClientV2.post(`${endpoint}/${id}/publish`)
    },

    showUserAnnouncements(id: number, query: UserQuery = {}) {
        const queryString = encodeURI(Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&'))
        return httpClientV2.get(`${endpoint}/user/${id}?${queryString}`)
    },

    showAnnouncementUsers(id: number, query: UserQuery = {}) {
        const queryString = encodeURI(Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&'))
        return httpClientV2.get(`${endpoint}/${id}/users?${queryString}`)
    },
}
