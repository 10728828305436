import {now} from '@/composable/useDate'
import NotificationService from '@/services/NotificationService'
import ToastNotification from '@/types/ToastNotification'
import WebNotification from '@/types/WebNotification'
import {ToastNotificationType} from '@/types/ToastNotificationType'
import {defineStore} from 'pinia'
import mixkitSfx from '@/assets/sound/mixkit-software.mp3'

export const useNotificationsStore = defineStore('notifications', {
    state: () => {
        return {
            webNotifications: [] as WebNotification[],
            toastNotifications: [] as ToastNotification[],
            toastNotificationIdCounter: 1,
            chatNotifications: [] as ToastNotification[],
            chatNotificationIdCounter: 1
        }
    },
    actions: {
        addToastNotification(notification: ToastNotification, type: ToastNotificationType = 'general') {
            const audio = new Audio(mixkitSfx);
            audio.play();

            switch (type) {
                case 'general':
                    notification.id = this.toastNotificationIdCounter++
                    this.toastNotifications.push(notification)
                    break
                case 'chat':
                    notification.id = this.chatNotificationIdCounter++
                    this.chatNotifications.push(notification)
                    break
            }
        },

        removeToastNotification(id: number, type: ToastNotificationType = 'general') {
            switch (type) {
                case 'general':
                    this.toastNotifications = this.toastNotifications.filter(toastNotification => id !== toastNotification.id)
                    break
                case 'chat':
                    this.chatNotifications = this.chatNotifications.filter(chatNotification => id !== chatNotification.id)
                    break
            }
        },

        addWebNotification(notification: WebNotification) {
            this.webNotifications.unshift(notification)
        },

        async markWebNotificationAsRead(notificationId: string | null) {
            if (notificationId !== null) {
                const index = this.webNotifications.findIndex(notification => notification.id === notificationId)

                if (index !== -1) {
                    this.webNotifications[index].readAt = now()
                }
            } else {
                this.webNotifications.forEach((notification) => {
                    notification.readAt = now()
                })
            }

            await NotificationService.markNotificationAsRead({id: notificationId})
        }
    }
})
