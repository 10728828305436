<template>
    <div class="text-center mb-4">
        <a href="." class="navbar-brand navbar-brand-autodark"><img src="/kiddocare-logo.png" height="36" alt=""></a>
    </div>
    <form class="card card-md" autocomplete="off">
        <div class="card-body">
            <div class="mb-3">
                <label class="form-label">Email address</label>
                <input type="email" class="form-control" placeholder="Enter email" autocomplete="off" :disabled="isLogin" v-model="email" @focus="clearError">
            </div>
            <div class="mb-2">
                <label class="form-label">Password</label>
                <div class="input-group input-group-flat">
                    <input type="password" class="form-control" placeholder="Password"  autocomplete="off" ref="inputPassword" :disabled="isLogin" v-model="password" @focus="clearError">
                    <span class="input-group-text cursor-pointer">
                        <a class="link-secondary" title="Show password" data-bs-toggle="tooltip" @click="revealPassword">
                            <icon v-if="isPasswordRevealed" name="eye" />
                            <icon v-else name="eye-off" />
                        </a>
                    </span>
                </div>
            </div>
            <div class="mb-2">
                <label class="form-check">
                    <input type="checkbox" class="form-check-input" :disabled="isLogin" v-model="rememberMe" @focus="clearError" />
                    <span class="form-check-label">Remember me</span>
                </label>
            </div>
            <div class="form-footer">
                <button class="btn btn-primary w-100" :disabled="isLogin" @click.prevent="login">
                    <div v-if="! isLogin">Sign In</div>
                    <div class="d-flex align-self-center mx-auto" v-else>
                        <div class="spinner-border"></div>
                    </div>
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
        </div>
    </form>
    <footer class="footer footer-transparent d-print-none text-center">
        <span>Version {{ backofficeVersion }}</span>
    </footer>
</template>

<script setup lang="ts">
import { PlatformEnum } from '@/enums/PlatformEnum'
import router from '@/router'
import AuthService from '@/services/AuthService'
import { useGeneralStore } from '@/stores/general'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const { backofficeVersion, isGoogleScriptLoaded } = storeToRefs(useGeneralStore())

const inputPassword = ref<any>(null)

const email = ref<string>('')
const password = ref<string>('')
const rememberMe = ref<boolean>(false)
const isLogin = ref<boolean>(false)
const isPasswordRevealed = ref<boolean>(false)
const errorMessage = ref<string>('')
const route = useRoute()

const revealPassword = () => {
    isPasswordRevealed.value = ! isPasswordRevealed.value
    inputPassword.value.type = isPasswordRevealed.value  ? 'text' : 'password'
}

const clearError = () => errorMessage.value = ''

const login = async () => {
    clearError()

    const input = {
        email: email.value,
        password: password.value,
        remember_me: rememberMe.value,
        platform_id: PlatformEnum.ID_BACKOFFICE,
        platform_type: PlatformEnum.TYPE_WEB,
    }

    if (input.email === '' || input.password === '') {
        return
    }

    isLogin.value = true

    try {
        window.google?.accounts.id.cancel()

        await AuthService.getCsrfCookie()
        await AuthService.login(input)

        await redirect()
    } catch (e) {
        handleError(e)
        window.google?.accounts.id.prompt()
        isLogin.value = false
    }
}

const validateGoogleToken = async (response: any) => {
    clearError()
    isLogin.value = true

    try {
        await AuthService.getCsrfCookie()
        await AuthService.validateGoogleToken({ access_token: response.credential })

        await redirect()
    } catch (e) {
        handleError(e)
        window.google?.accounts.id.prompt()
        isLogin.value = false
    }
}

const handleError = (e: any) => {
    const data = e.response.data
    if (data.error) {
        errorMessage.value = data.error
    } else if (data.message) {
        errorMessage.value = data.message
    }
}

const redirect = async () => {
    const routeTo = route.query.hasOwnProperty('redirectPath') ? { path: route.query.redirectPath } : { name: 'home-page' }
    await router.push(routeTo)
}

onMounted(() => {
    const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID

    if (googleClientId !== undefined) { // only trigger Google SSO if google client id is present
        if (isGoogleScriptLoaded.value) {
            window.google.accounts.id.prompt()
        } else {
            const googleScript = document.createElement('script');
            googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
            document.head.appendChild(googleScript)

            googleScript.onload = () => {
                const options: any = {
                    client_id: googleClientId,
                    callback: validateGoogleToken,
                    auto_select: false,
                    cancel_on_tap_outside: false,
                    context: 'signin',
                }

                if (import.meta.env.VITE_API_BASE_URL.includes('.kiddocare.my')) {
                    options['hd'] = 'kiddocare.my'
                }

                window.google.accounts.id.initialize(options)
                window.google.accounts.id.prompt()
            }

            isGoogleScriptLoaded.value = true
        }
    }
})
</script>
