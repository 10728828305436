export enum BunnyNetStatus {
    STATUS_QUEUED = 0,
    STATUS_PROCESSING = 1,
    STATUS_ENCODING = 2,
    STATUS_FINISHED = 3,
    STATUS_RESOLUTION_FINISHED = 4,
    STATUS_FAILED = 5,
    STATUS_PRESIGNED_UPLOAD_STARTED = 6,
    STATUS_PRESIGNED_UPLOAD_FINISHED = 7,
    STATUS_PRESIGNED_UPLOAD_FAILED = 8,
    STATUS_CAPTIONS_GENERATED = 9,
    STATUS_TITLE_OR_DESCRIPTION_GENERATED = 10,
}
