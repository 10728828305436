<template>
    <kc-modal modal-id="content-type-form-modal" :modal-keyboard="false" :modal-title="modalTitle">
        <div class="mb-1">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': v$.inputUser.name.$error }" v-model="inputUser.name"
                @input="clearError" />
            <div class="invalid-feedback">{{ v$.inputUser.name.$errors[0]?.$message }}</div>
        </div>
        <div class="mb-3">
            <label class="form-check form-switch mb-0 ms-auto">
                <input class="form-check-input" type="checkbox" v-model="inputUser.is_show_title">
                <span class="form-check-label">
                    {{ inputUser.is_show_title ? 'Show' : 'Hide' }}
                </span>
            </label>
        </div>
        <div class="mb-3">
            <label class="form-label">Media Presets</label>
                <vue-select 
                    :options="platformContentMediaPresets"
                    :filterable="false"
                    label="name"
                    v-model="inputUser.media_preset_id"
                    :class="{ 'is-invalid': v$.inputUser?.media_preset_id?.$error }"
                    @change="clearError" 
                    :reduce="(mediaPreset: any) => mediaPreset.id"
                    placeholder="Media Preset"
                />
            <div class="invalid-feedback">{{ v$.inputUser?.media_preset_id?.$errors[0]?.$message }}</div>
            <div class="mt-1">{{ mediaPresetDimension }}</div>
        </div>
        <div class="mb-3">
            <label class="form-label">Content Label</label>
            <input type="text" class="form-control" v-model="inputUser.content_label" />
        </div>
        <div class="mb-3">
            <label class="form-label">Autoplay Interval <small>(seconds)</small></label>
            <input type="number" class="form-control" v-model="inputUser.content_interval" />
        </div>
        <div class="mb-3">
            <label class="form-check form-switch mb-0 ms-auto">
                <input class="form-check-input" type="checkbox" v-model="inputUser.is_top_section">
                <span class="form-check-label">
                    {{ inputUser.is_top_section ? 'Top Section' : 'Below Section' }}
                </span>
            </label>
        </div>
        <div class="mb-3">
            <label class="form-check form-switch mb-0 ms-auto">
                <input class="form-check-input" type="checkbox" v-model="inputUser.is_show_content_details">
                <span class="form-check-label">
                    {{ inputUser.is_show_content_details ? 'Show Content Details' : 'Hide Content Details' }}
                </span>
            </label>
        </div>
        <div class="mb-3">
            <label class="form-check form-switch mb-0 ms-auto">
                <input class="form-check-input" type="checkbox" v-model="inputUser.is_enabled">
                <span class="form-check-label">
                    {{ inputUser.is_enabled ? 'Enabled' : 'Disabled' }}
                </span>
            </label>
        </div>
        <template #footer>
            <button type="button" id="close-bank-form-modal-button" class="btn btn-link link-secondary"
                data-bs-dismiss="modal">
                Close
            </button>
            <button class="btn btn-primary ms-auto" :class="{ disabled: isSaving }" type="submit" @click="save">
                <span v-if="isSaving" class="spinner-border spinner-border-sm me-2" role="status"></span>
                {{ isSaving ? 'Saving' : 'Save' }}
            </button>
        </template>
    </kc-modal>
</template>

<script setup lang="ts">
import PlatformContentTypeService from '@/services/PlatformContentTypeService'
import { useNotificationsStore } from '@/stores/notifications'
import PlatformContentType from '@/types/PlatformContentType'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { AxiosError } from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import PlatformContentMediaPreset from '@/types/PlatformContentMediaPreset'
import PlatformContentMediaPresetService from '@/services/PlatformContentMediaPresetService'
import ErrorBag from '@/types/ErrorBag'

const props = defineProps<{
    type?: PlatformContentType
    currentMenuType: number
}>()

const emit = defineEmits<{
    (e: 'save'): void
}>()

const { addToastNotification } = useNotificationsStore()

const inputUser = ref<{
    name: string
    type: number
    content_label?: string
    content_interval?: number
    media_preset_id?: number
    is_top_section: boolean
    is_show_title: boolean
    is_show_content_details: boolean
    is_enabled: boolean
}>({
    name: '',
    type: props.currentMenuType,
    is_top_section: false,
    is_show_title: true,
    is_show_content_details: true,
    is_enabled: false,
})
const isSaving = ref<boolean>(false)
const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

const platformContentMediaPresets = ref<PlatformContentMediaPreset[]>([])

const mode = computed(() => props.type ? 'updated' : 'created')
const modalTitle = computed(() => mode.value === 'created' ? 'Add New Content Type' : 'Edit Content Type')
const notificationMessage = computed(() => `${inputUser.value.name} is successfully ${mode.value}`)

const rules = computed(() => ({
    inputUser: {
        name: {
            required: helpers.withMessage(
                'The name field is required',
                required
            )
        }
    }
}))

const mediaPresetDimension = computed(() => {
    let media_preset_id = inputUser.value.media_preset_id
    
    let mediaPreset = platformContentMediaPresets.value.filter((mediaPreset: PlatformContentMediaPreset) => { return mediaPreset.id == media_preset_id })

    if(mediaPreset.length > 0) {
        let preset = mediaPreset[0]

        return (`Dimension: ${preset.preset?.width ?? 0}px X ${preset.preset?.height ?? 0}px (${preset.preset?.width_setting} width setting)`)
    }
    
    return ''
})

const v$ = useVuelidate(rules, { inputUser }, { $scope: false, $externalResults })

const save = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (!validated) return

    isSaving.value = true
    
    try {
        switch (mode.value) {
            case 'created':
                await PlatformContentTypeService.store(inputUser.value)
                break
            default:
                if (props.type === undefined) {
                    addToastNotification({
                        type: 'danger',
                        message: 'Bank info cannot be loaded.'
                    })

                    return
                }

                await PlatformContentTypeService.update(props.type.id, inputUser.value)
                break
        }

        document.getElementById('close-bank-form-modal-button')?.click()

        emit('save')

        addToastNotification({
            type: 'success',
            message: notificationMessage.value
        })
    } catch (error) {
        handleError(error)
    }

    isSaving.value = false
}

const handleError = (error: any) => {
    if (error instanceof AxiosError && error.response && error.response.status === 422) {
        Object.assign($externalResults.value.inputUser, error.response.data.errors)
    } else {
        addToastNotification({
            type: 'danger',
            message: 'Internal server error. Please contach tech team.'
        })
    }
}

const clearError = () => {
    $externalResults.value.inputUser = {}
}

onMounted(async () => {
    const response = await PlatformContentMediaPresetService.index()
    platformContentMediaPresets.value = response.data.data

    document.getElementById('content-type-form-modal')?.addEventListener('hidden.bs.modal', () => {
        v$.value.$reset()
        inputUser.value.name = props.type?.name ?? ''
        inputUser.value.content_label = props.type?.contentLabel
        inputUser.value.content_interval = props.type?.contentInterval
        inputUser.value.media_preset_id = props.type?.media_preset_id
        inputUser.value.is_top_section = props.type?.isTopSection ?? false
        inputUser.value.is_show_title = props.type?.isShowTitle ?? true
        inputUser.value.is_show_content_details = props.type?.isShowContentDetails ?? true
        inputUser.value.is_enabled = props.type?.isEnabled ?? false
    })
})

watch(
    () => props.type,
    (type) => {
        inputUser.value.name = type?.name ?? ''
        inputUser.value.content_label = type?.contentLabel
        inputUser.value.content_interval = type?.contentInterval
        inputUser.value.media_preset_id = type?.media_preset_id
        inputUser.value.is_top_section = type?.isTopSection ?? false
        inputUser.value.is_show_title = type?.isShowTitle ?? true
        inputUser.value.is_show_content_details = type?.isShowContentDetails ?? true
        inputUser.value.is_enabled = type?.isEnabled ?? false
    }
)

watch(
    () => props.currentMenuType,
    (menuType) => {
        inputUser.value.type = menuType
    }
)

</script>