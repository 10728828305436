import UserService from "@/services/UserService"
import { useNotificationsStore } from "@/stores/notifications"
import User from "@/types/User"


export function useEmailVerification() {

    const { addToastNotification } = useNotificationsStore()

    const sendVerificationCode = async (user: User) => {
        if (!user) {
            addToastNotification({
                type: 'danger',
                message: 'Unable to send verification code. User not found.'
            })
            return
        }

        const fullName = user.mainProfile?.fullName || 'this user'

        try {
            const result = await window.Swal.fire({
                title: 'Confirm Resend Verification Code',
                html: `
                <p>This will send a new verification code to <strong>${fullName}</strong>.</p>
                <p class="mt-2 text-sm text-muted">
                  User will receive a unique verification code via email to verify their account. 
                  Any previously sent codes will be invalidated.
                </p>
              `,
                showCancelButton: true,
                confirmButtonText: 'Yes, Send Now',
                cancelButtonText: 'Cancel'
            })

            if (!result.isConfirmed) return

            window.Swal.fire({
                title: 'Sending Verification Email',
                text: 'Please wait...',
                allowOutsideClick: false,
                didOpen: () => {
                    window.Swal.showLoading()
                }
            })

            await UserService.resendEmailVerification(user.id)
            window.Swal.close()

            addToastNotification({
                message: `Code for email verification sent to ${fullName}.`,
                type: 'success'
            })
        } catch (error: any) {
            window.Swal.close()

            const errorMessages: Record<number, string> = {
                400: 'Email verification is not applicable for this user.',
                401: 'Unauthorized to send code for email verification.',
                403: 'Permission denied.',
                429: 'Too many email verification attempts. Please wait 15 minutes before trying again.',
                500: 'Server error. Unable to send code for verification email.'
            }

            const status = error.response?.status || 500
            const defaultMessage = 'An unexpected error occurred. Please contact support.'

            addToastNotification({
                message: errorMessages[status] || defaultMessage,
                type: 'danger'
            })

            console.error('Resend code for email verification failed:', error)
        }
    }

    return {
        sendVerificationCode
    }
}