import httpClientV2 from '@/services/HttpClientV2'
import {getQueryString} from '@/composable/http'
import VoucherQuery from '@/types/query/VoucherQuery'
import StoreVoucherFormData from '@/types/formData/StoreVoucherFormData'
import UpdateVoucherFormData from '@/types/formData/UpdateVoucherFormData'

const endpoint = '/backoffice/vouchers'

export default {
    index(query: VoucherQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },
    store(data: StoreVoucherFormData) {
        return httpClientV2.post(endpoint, data)
    },
    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },
    update(id: number, data: UpdateVoucherFormData) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },
    destroy(id: number) {
        return httpClientV2.delete(`${endpoint}/${id}`)
    },
    sendEmailCode(id: number, data: any) {
        return httpClientV2.post(`${endpoint}/${id}/email`, data)
    },
}
