<template>
    <div>
        <router-link :to="{ name: 'notification-cohort-index' }" class="d-inline-flex align-items-center" role="button">
            <icon name="chevron-left" />
            <span>Back to Cohort</span>
        </router-link>
        <overlay-spinner :is-showing="isFetchingCohort && isFetchingCohortUser"
            v-if="isFetchingCohort && isFetchingCohortUser" />
        <div>
            <div class="page-header mb-4">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="page-title">{{ cohort.name }}</h2>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Name</span>
                            </p>
                            <span class="fw-bold">{{ cohort.name }}</span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Cohort Week Number</span>
                            </p>
                            <span class="fw-bold">{{ cohort.weekNumber === 0 ? '-' : cohort.weekNumber }}</span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Total User</span>
                            </p>
                            <span class="fw-bold">{{ cohort.userCount }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h4 class="card-title">Users in this Cohort</h4>
                </div>
                <div class="card-body">
                    <div class="row mb-4 align-items-end gap-3 gap-md-0">
                        <div class="col-12 col-md">
                            <label class="form-label">Name</label>
                            <input class="form-control" type="text" v-model="inputFilter.name"
                                @keyup.enter="fetchCohortUser">
                        </div>
                        <div class="col-12 col-md">
                            <label class="form-label">Online ID</label>
                            <input class="form-control" type="email" v-model="inputFilter.onlineId"
                                @keyup.enter="fetchCohortUser">
                        </div>
                        <div class="col-12 col-md-auto ms-auto">
                            <button-filter @reset="resetFilter" @filter="fetchCohortUser"
                                :is-loading="isFetchingCohort"></button-filter>
                        </div>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Online ID</th>
                                <th>Name</th>
                                <th class="text-center">First Completed Session Date</th>
                                <th class="text-center">Register Date</th>
                                <th class="text-center">Complete Profile Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="cohortUsers.length > 0">
                            <tr v-for="cohortUser in cohortUsers" :key="cohortUser.id">
                                <td>{{ cohortUser.user?.mainProfile?.onlineId ?? '-' }}</td>
                                <td>{{ cohortUser.user?.mainProfile?.fullName ?? '-' }}</td>
                                <td class="text-center">{{ cohortUser.convertedAt ?
                                    formatDate(cohortUser.convertedAt, 'DD-MMM-YYYY') : '-' }}</td>
                                <td class="text-center">{{ formatDate(cohortUser.user.createdAt, 'DD-MMM-YYYY') }}
                                </td>
                                <td class="text-center">{{ cohortUser.user.customerOnboarding?.updatedAt ?
                                    formatDate(cohortUser.user.customerOnboarding?.updatedAt, 'DD-MMM-YYYY') : '-'
                                    }}</td>
                                <td>
                                    <button class="btn btn-icon btn-primary"
                                        @click.prevent="viewUser(cohortUser.userId)">
                                        <icon name="eye" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6" class="text-center">No data</td>
                            </tr>
                        </tbody>
                    </table>
                    <overlay-spinner :is-showing="isFetchingCohortUser" />
                    <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage"
                        @goto-page="gotoPage" @per-page-updated="updatePerPage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Cohort from '@/types/Cohort'
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications'
import CohortService from '@/services/CohortService'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import UserFilter from '@/types/Filter'

const { addToastNotification } = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const route = useRoute()
const router = useRouter();
const isFetchingCohort = ref<boolean>(false)
const isFetchingCohortUser = ref<boolean>(false)
const cohortId = Number(route.params.cohortId)
const cohort = ref<Cohort>({
    id: 0,
    name: '',
    weekNumber: 0,
    startedAt: '',
    completedAt: '',
    createdAt: '',
    userCount: 0
});

const inputFilter = ref<UserFilter>({
    name: null,
    onlineId: null,
})

const cohortUsers = ref<any>([])

function fetchCohort() {
    isFetchingCohort.value = true

    CohortService.show(cohortId)
        .then(({ data: { data } }) => {
            cohort.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingCohort.value = false)
}

function fetchCohortUser() {
    isFetchingCohortUser.value = true;

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    CohortService.getCohortUsers(cohortId, query)
        .then(({ data: { data, meta } }) => {
            cohortUsers.value = data
            updateMetaPage(meta)
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingCohortUser.value = false)
}

function viewUser(id: number) {
    router.push({ name: 'customer-show', params: { 'userId': id } })
}


function resetFilter() {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }   

    fetchCohortUser()
}


onMounted(() => {
    fetchCohort();
    fetchCohortUser();
})

watch(
() => metaPageTriggered.value,
() => fetchCohortUser()
)
</script>