<template>
    <AnnouncementForm :announcement="announcement" v-if="announcement" />
    <AnnouncementUser :announcementId="announcementId" v-if="announcement?.recipient == '4'"/>

    <overlay-spinner :is-showing="isFetching"></overlay-spinner>
</template>

<script setup lang="ts">
import AnnouncementForm from '@/pages/marketings/announcements/AnnouncementForm.vue'
import AnnouncementService from '@/services/AnnouncementService'
import { useNotificationsStore } from '@/stores/notifications'
import Announcement from '@/types/Announcement'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import AnnouncementUser from './AnnouncementUser.vue'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const announcementId = Number(route.params.announcementId)
const announcement = ref<Announcement>()
const isFetching = ref<boolean>(true)

AnnouncementService.show(announcementId)
    .then(({ data: { data } }) => {
        announcement.value = data
    })
    .catch(() => {
        addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
    })
    .finally(() => isFetching.value = false)

</script>
